import React from "react";

function home() {
  return (
    <section id="hero">
        <div className="hero main">
          <div className="">
            <h1>Hello,<span></span></h1>
            <h1>I am<span></span></h1>
            <h1>Javian<span></span></h1>
            <a href="/about" type="button" className="cta">intro</a>
          </div>
          
        </div>

        <div className="text-center">
        <a href="https://www.instagram.com/manueljanderson_/">
          <i class="fab fa-instagram fa-3x pe-3 col-lg-3 col-md-3 col-sm-6"></i>
        </a>
        <a href="https://www.linkedin.com/in/andersonjavian/">
          <i class="fab fa-linkedin fa-3x pe-3 col-lg-3 col-md-3 col-sm-6"></i>
        </a>
        <a href="https://twitter.com/manueljanderson">
          <i class="fab fa-twitter fa-3x pe-3 col-lg-3 col-md-3 col-sm-6"></i>
        </a>
        <a href="https://www.facebook.com/OfficialManuel1">
          <i class="fab fa-facebook fa-3x pe-3 col-lg-3 col-md-3 col-sm-6"></i>
        </a>
      </div>

        <div className="footer">
            <hr />
            <p className="text-xs-center text-white">
              &copy;{new Date().getFullYear()} Zing Studio Productions created by Javian R.
              Anderson - All Rights Reserved
            </p>
          </div>
      </section>
  );
}

export default home;
