import React, { useEffect } from "react";
import { Footer2, Navbar2 } from "../components";
import { About as Abouty } from "../components";
import Helmet from "react-helmet";
import image from "../images/javian_logo.png";

function About() {
  const title = "About Page | Javian Anderson";
  const url = "https://andersonja.tech/about";
  const image = "https://i.ibb.co/9yMq0wf/javian-logo2.png";
  const desc = "Learn More about Javian Anderson";
  return (
    <div>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>About Page | Javian Anderson</title>
        <meta name="description" content="Learn More about Javian Anderson" data-react-helmet="true"></meta>

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" data-react-helmet="true"></meta>
        <meta property="og:url" content="https://andersonja.tech/about" data-react-helmet="true"></meta>
        <meta property="og:title" content="About Page | Javian Anderson" data-react-helmet="true"></meta>
        <meta property="og:description" content="Learn More about Javian Anderson" data-react-helmet="true"></meta>
        <meta property="og:image" content="https://i.ibb.co/9yMq0wf/javian-logo2.png" data-react-helmet="true"></meta>

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image"></meta>
        <meta property="twitter:url" content="https://andersonja.tech/about" data-react-helmet="true"></meta>
        <meta property="twitter:title" content="About Page | Javian Anderson" data-react-helmet="true"></meta>
        <meta property="twitter:description" content="Learn More about Javian Anderson" data-react-helmet="true"></meta>
        <meta property="twitter:image" content="https://i.ibb.co/9yMq0wf/javian-logo2.png" data-react-helmet="true"></meta>
      </Helmet>
      <Navbar2 />
      <Abouty />
      <Footer2 />
    </div>
  );
}

export default About;
