import React from "react";

const Footer2 = () => {
  return (
    <div className="container">
      <div className="text-center">
        <h2 className="text-uppercase connect">Connect with me!</h2>
        <a href="https://www.instagram.com/manueljanderson_/">
          <i class="fab fa-instagram fa-2x pe-3 col-lg-3 col-md-3 col-sm-6"></i>
        </a>
        <a href="https://www.linkedin.com/in/andersonjavian/">
          <i class="fab fa-linkedin fa-2x pe-3 col-lg-3 col-md-3 col-sm-6"></i>
        </a>
        <a href="https://twitter.com/manueljanderson">
          <i class="fab fa-twitter fa-2x pe-3 col-lg-3 col-md-3 col-sm-6"></i>
        </a>
        <a href="https://www.facebook.com/OfficialManuel1">
          <i class="fab fa-facebook fa-2x pe-3 col-lg-3 col-md-3 col-sm-6"></i>
        </a>
      </div>
      <div className="footer">
        <hr />
        <p className="text-xs-center">
          &copy;{new Date().getFullYear()} Zing Studio Productions created by Javian R.
          Anderson - All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer2;
