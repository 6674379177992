import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { Navbar2 } from "../../components";
import Slider from "../../components/Slider/Slider";
import blog1 from "../../images/blogs/blog1.jpg";
import entrep from "../../images/blogs/entrep.JPG";
import two_fa from "../../images/blogs/2fa.jpg";
import { Footer2 } from "../../components";
import { Link } from "react-router-dom";

const initialBlogs = [
  {
    title: "What is 2 Factor Authentication?",
    image: two_fa,
    subtitle:
      "2FA Unveiled: Exploring Its Benefits and Why You Should Embrace It Online.",
    slug: "2-factor-authentication",
    flipped: true,
  },
  {
    title: "How I ended up doing my degree program",
    image: blog1,
    subtitle:
      "Transitioning from High School to University gave me a whole lot of decisions to make, but the most important was how I ended up doing Computer Science when it was never an option before.",
    slug: "how-i-ended-up-doing-computer-science",
    flipped: false,
  },
  {
    title: "My Entrepreneurial Journey",
    image: entrep,
    subtitle:
      "Having rediscovered the love I had being around computer and other devices, I had started couple business ventures in my university journey.",
    slug: "my-entrepreneurial-journey",
    flipped: true,
  },
];

function Blog_page() {
  const [searchInput, setSearchInput] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState(initialBlogs);

  const filterBlogs = useCallback(() => {
    const filtered = initialBlogs.filter(
      (blog) =>
        blog.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        blog.subtitle.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredBlogs(filtered);
  }, [searchInput]);

  useEffect(() => {
    filterBlogs();
  }, [searchInput, filterBlogs]);

  return (
    <div>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>Blogs | Javian Anderson</title>
        <meta
          name="description"
          content="View the blogs written by Javian Anderson"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://andersonja.tech/blogs" />
        <meta property="og:title" content="Blogs | Javian Anderson" />
        <meta
          property="og:description"
          content="View the blogs written by Javian Anderson"
        />
        <meta
          property="og:image"
          content="https://neilpatel.com/wp-content/uploads/2018/10/blog.jpg"
        />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://andersonja.tech/blogs" />
        <meta property="twitter:title" content="Blogs | Javian Anderson" />
        <meta
          property="twitter:description"
          content="View the blogs written by Javian Anderson"
        />
        <meta
          property="twitter:image"
          content="https://neilpatel.com/wp-content/uploads/2018/10/blog.jpg"
        />
      </Helmet>
      <Navbar2 />
      <section id="blogs">
        <div className="pt-5">
          <h1 className="section-title p-5">Blogs</h1>
        </div>

        <div className="search-bar" id="search-bar">
          {/* Search bar input */}
          <input
            type="text"
            className="search-input"
            id="search-bar"
            placeholder="Seek and you shall find..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>

        {/* Iterate through filteredBlogs */}
        {filteredBlogs.map((blog) => (
          <Link key={blog.title} aria-current="page" to={`/${blog.slug}`}>
            <Slider
              imageSrc={blog.image}
              title={blog.title}
              flipped={blog.flipped}
              subtitle={blog.subtitle}
            />
          </Link>
        ))}
        <Footer2 />
      </section>
    </div>
  );
}

export default Blog_page;
