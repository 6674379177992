import React, { useEffect } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.css";
import "magnific-popup";
// import logo from "../images/background/image13.png";
// import logo1 from "../images/image1.png";

// COMPONENTS
import { Link } from "react-router-dom";
import { Navbar, Footer, Footer2,Services, Projects, About, Home, Contact, Navbar2} from "../components";
import Helmet from "react-helmet";


// IMAGES

const MainPage = () => {
  // useEffect(() => {
  //   document.title = "Javian Anderson's Personal Website";
  // }, []);
  return (
    <div className="" id="">
     
      <Navbar2/>
      <Home/>
      {/* <Services/> */}
      {/* <Projects/> */}
      {/* <About/> */}
      {/* <Contact/> */}
      {/* <Footer2 id=""/> */}
      {/* <Footer/> */}
    </div>
  );
};

export default MainPage;
