import React, { useEffect } from "react";
import { Footer2, Navbar2 } from "../../components";
import Slider from "../../components/Slider/Slider";
import image from "../../images/blogs/graduation.JPG";
import image2 from "../../images/blogs/graduation1.JPG";
import dc from "../../images/blogs/dc.jpg";
import Helmet from "react-helmet";
// import { Link } from "react-router-dom";

function Blog1() {
  const title = "How I ended up choosing my Major | Javian Anderson";
  const url = "https://andersonja.tech/how-i-ended-up-doing-computer-science";
  const image = "https://i.ibb.co/r0bvmQW/blog1.jpg";
  const desc = "Transitioning from High School to University gave me a whole lot of decisions to make, but the most important was how I ended up doing Computer Science when it was never an option before.";
  return (
    <div>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>{title}</title>
        <meta name="description" content={desc} data-react-helmet="true"></meta>

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" data-react-helmet="true"></meta>
        <meta property="og:url" content={url} data-react-helmet="true"></meta>
        <meta property="og:title" content={title} data-react-helmet="true"></meta>
        <meta property="og:description" content={desc} data-react-helmet="true"></meta>
        <meta property="og:image" content={image} data-react-helmet="true"></meta>

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image"></meta>
        <meta property="twitter:url" content={url} data-react-helmet="true"></meta>
        <meta property="twitter:title" content={title}data-react-helmet="true"></meta>
        <meta property="twitter:description" content={desc} data-react-helmet="true"></meta>
        <meta property="twitter:image" content={image} data-react-helmet="true"></meta>
      </Helmet>
      <Navbar2 />
      <section id="blogs">
        <div className="blogs main">
          <div className="blogs-header pb-4">
            <h2 className="section-title">
              How did I end up doing Computer Science?
            </h2>
            <h3>Timeline: March-April 2018</h3>
            <h3 className="pt-2">Written: June 12,2022</h3>
          </div>

          {/* <div className="container text-center">
            <img src={image} alt="" className="img-fluid pot p-5" />
          </div> */}

          <div>
            <p className="m-5">
              Hi all! I am Javian Anderson and in the spirit of trying new
              things, I am undertaking my first ever blog writing! This is a new
              journey for me and I thought to myself, what better story to start
              with than the inspiration that got me to this moment.
            </p>
            <p className="m-5">
              During my high school years at The Titchfield High, I was always a
              consistent kid, though I must admit, knowing my capabilities, I
              kind of just glided through my first few years while I enjoyed the
              time but I always placed in the top 10 of my class. Fast forward
              to the ninth grade I decided that I needed to actually not slip up
              as this was the year where your performances decide which
              discipline you are placed in and I was aiming to be in the
              sciences.
            </p>

            <div className="container text-center">
              <img src={image2} alt="" className="img-fluid" />
              <h5>Graduation 2016</h5>
            </div>

            <p className="m-5">
              In true jumping the timeline fashion; 9 CSEC passes, 3 CAPE
              passes, a quiz quarterfinal, and several other mentions later, I
              was sitting there in the Biology lab at school reviewing my offer
              from UWI and singing to myself “Yep, Gonna do BioChemistry”. I’ve
              always had a love for Mathematics so with Chemistry, it was like a
              close second love. Then there was Biology with the intimacy of
              knowing what happens in humans and other living organisms, which
              was so enticing, except for the part when I had to be drawing(wow
              those were the hardest things to do!). I never wanted to end up
              teaching Mathematics so the decision I made came down to what was
              the most economical field to go in that will set me up for the
              future, and at the time, an 18-year-old thought that choice was
              Bio-Chemistry.
            </p>
            <p className="m-5">
              So here I was, after weeks of telling everyone I was gonna do
              Bio-Chemistry, my coach(and mentor), Chase Roberts, JP, said to me
              that he didn’t think that was really my niche. I was shocked of
              course because he had done a Bachelor’s in the same field(which I
              had forgotten in the longest while to be very honest) and so I
              thought he was going to say “Yeah man, definitely!”. But as I
              engaged in the conversation, and he was telling me what not to do,
              I was taken aback because one of the things he potentially warned
              me could happen was to end up doing the thing I never wanted to
              do, which was teaching. It was not that I was against teaching,
              but I knew that was never going to be a field I was interested in
              under no circumstances ever.
            </p>

            <div className="container text-center">
              <img src={dc} alt="" className="img-fluid" />
              <h5>Myself and Mr. Roberts at a Quiz Rally hosted by DeCarteret College</h5>
            </div>
            
            <p className="m-5">
              Among the other things he warned me about was how the market is
              not only competitive for Bio-Chem graduates but very small, so
              small you’ll end up taking a job far left from what you did in
              school. So as I was sitting there engaged, I asked “Then what
              exactly fits me?”. I remember he said, “Manuel, look at what
              you’ve been around for years and what you are gifted at”(Manuel
              was my nickname I got while in quiz). I was still confused but
              then he said “Computers, technical stuff”.
            </p>
            <p className="m-5">
              I was left dumbfounded because he was right. I had gotten my first
              computer at the tender age of around 7/8 and ever since I have
              done many things on a computer that you would find normal children
              doing. You could call me a hacker, but not the kind you are used
              to. Ever since my first computer, I have partaken in repairing
              Computer Hardware by pulling down an entire machine and replacing
              parts, granting software requests for myself and those around me,
              fixing software and hardware-related issues on powered-on
              computers to even setting up networks in my house among other
              things. I even used to deliberately go to a computer store to fix
              issues I knew I could fix myself and then wonder how people fall
              for their scam because I thought they were fakers and I could
              clearly do a better job than they(Yes, I was a menace). So I’ve
              always been experimental with computers and other devices but I
              have never thought about a career in IT/Com. Sci. up until that
              point in time.
            </p>
            <p className="m-5">
              Was I scared that this might actually be my path? Yes. But was I
              going to take the chance? Also Yes. I had to go home and convince
              my mother that this was going to pay off though because she didn’t
              see that coming and neither did I! However, I stuck with the
              decision and 4 years later, I think it was the right one. I
              actively enjoyed my degree program, even the times when shit did
              hit the fan and I felt lost, I would not trade it for another
              program.
            </p>
          </div>
        </div>
        <Footer2/>
      </section>
    </div>
  );
}

export default Blog1;
