import React, { Component } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

// Scrolling Component
import { ScrollToTop } from "./components/index.js";

// PAGES
import MainPage from "./pages/index.jsx";
import About from "./pages/about.jsx";
import projectspage from "./pages/projectspage.jsx";
import blog_page from "./pages/blogs/main_page.jsx";
import blog1 from "./pages/blogs/blog1.jsx";
import blog2 from "./pages/blogs/blog2.jsx";
import blog3 from "./pages/blogs/blog3.jsx"
import notfound from "./pages/404.jsx";
import Helmet from "react-helmet";

class App extends Component{
  render(){
    return(
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={MainPage} />
            <Route exact path="/about" component={About} />
            <Route exact path="/projects" component={projectspage} />
            <Route exact path="/blogs" component={blog_page} />
            <Route exact path="/how-i-ended-up-doing-computer-science" component={blog1} />
            <Route exact path="/my-entrepreneurial-journey" component={blog2} />
            <Route exact path="/2-factor-authentication" component={blog3} />
            <Route component={notfound}/>
            {/* <Redirect to="/404" /> */}
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
