import React, { useEffect } from "react";
import { Footer2, Navbar2 } from "../components";

const NotFoundPage = () => {
    useEffect(() => {
        document.title = "Error 404";
      }, []);
  return (
    <div className="text-center p-5">
      <Navbar2 />
      <h2 className="p-5">Page Not Found</h2>
      {/* <Footer2 /> */}
    </div>
  );
};

export default NotFoundPage;
