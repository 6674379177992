import React from "react";

function services() {
  return (
    <section id="services">
      <div className="services main">
        <div className="service-top">
          <h1 className="section-title">Services</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus
            veritatis nobis blanditiis tenetur non, architecto voluptates autem
            deserunt incidunt culpa?
          </p>
        </div>
        <div className="service-bottom">
          
        <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="service-item">
              <div className="icon">
                {/* USE ICONS 8 FOR EMBEDDED COOL ICONS */}
                <img src="https://img.icons8.com/color/48/undefined/computer-support.png" />
              </div>
              <h2>Web Design</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto
                ullam atque perspiciatis architecto placeat harum?Lorem, ipsum dolor.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="service-item">
              <div className="icon">
                {/* USE ICONS 8 FOR EMBEDDED COOL ICONS */}
                <img src="https://img.icons8.com/color/48/undefined/computer-support.png" />
              </div>
              <h2>Web Design</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto
                ullam atque perspiciatis architecto placeat harum?
              </p>
            </div>
          </div><div className="col-lg-3 col-md-6 col-sm-12">
            <div className="service-item">
              <div className="icon">
                {/* USE ICONS 8 FOR EMBEDDED COOL ICONS */}
                <img src="https://img.icons8.com/color/48/undefined/computer-support.png" />
              </div>
              <h2>Web Design</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto
                ullam atque perspiciatis architecto placeat harum?
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="service-item">
              <div className="icon">
                {/* USE ICONS 8 FOR EMBEDDED COOL ICONS */}
                <img src="https://img.icons8.com/color/48/undefined/computer-support.png" />
              </div>
              <h2>Web Design</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto
                ullam atque perspiciatis architecto placeat harum?
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default services;
