import React from "react";
import image from "../../images/awards2.jpg";
import { Link } from "react-router-dom";

function about() {
  return (
    <section id="about">
      <div className="about main">
        <div className="col-left">
          <div className="about-img">
            <img src={image} alt="" />
          </div>
        </div>
        <div className="col-right">
          <h1 className="section-title">Javian Anderson</h1>
          <h2>CyberSecurity Professional</h2>
          <p>
            Javian Anderson is a Computer Science major who now uses his skills
            in the field of CyberSecurity. He hails from the parish of
            Portland,Jamaica and is a multidimensional individual who has many
            interests in technology, leadership, sports, and music. Having
            served in many leadership capacities, most recently that of Deputy
            Hall Chairman of Irvine Hall at The University of the West Indies,
            Mona, Javian is using his talents to take on the world through his
            love for technology. A Christian at heart, he is socially inspired
            to lead those around him and be a positive light to others. Javian
            takes pride in providing the best service in any role given. His
            hope is that one day, Jamaica will be in the top ranking of the
            "Safe and Secure Digital Markets" global ladder.
          </p>
          <p>
            <b><em>"Keep Moving Forward"</em></b> - Lewis, Meet the Robinsons, 2007
          </p>
          <div className="text-center">
            {/* <a href="/about" type="button" className="cta">
              intro
            </a> */}
            <Link
              to="/files/JAVIAN_ANDERSON_RESUME.pdf"
              target="_blank"
              download
            >
              <button type="button" className="cta">
                Resume
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default about;
