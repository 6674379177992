import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { Footer2, Navbar2, Projects } from "../components";

function Projectspage() {
  const title = "Projects | Javian Anderson";
  const url = "https://andersonja.tech/projects";
  const image = "https://www.icj.org/wp-content/uploads/2015/07/Projects-copy.jpg";
  const desc = "View the projects done by Javian Anderson";
  return (
    <div>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>{title}</title>
        <meta name="description" content={desc} data-react-helmet="true"></meta>

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" data-react-helmet="true"></meta>
        <meta property="og:url" content={url} data-react-helmet="true"></meta>
        <meta property="og:title" content={title} data-react-helmet="true"></meta>
        <meta property="og:description" content={desc} data-react-helmet="true"></meta>
        <meta property="og:image" content={image} data-react-helmet="true"></meta>

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image"></meta>
        <meta property="twitter:url" content={url} data-react-helmet="true"></meta>
        <meta property="twitter:title" content={title}data-react-helmet="true"></meta>
        <meta property="twitter:description" content={desc} data-react-helmet="true"></meta>
        <meta property="twitter:image" content={image} data-react-helmet="true"></meta>
      </Helmet>
      <Navbar2 />
      <Projects />
      <Footer2 />
    </div>
  );
}

export default Projectspage;
