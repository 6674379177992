import React from "react";
// import image from "../../images/awards2.jpg";
import evote from "../../images/projects/evote.jpg";
import catering from "../../images/projects/catering.jpg";
import nscam from "../../images/projects/nscam.png";

function projects() {
  return (
    <section id="projects">
      <div className="projects main">
        <div className="projects-header">
          <h1 className="section-title">Projects</h1>
        </div>
        <div className="all-projects">
          <div className="project-item">
            <div className="project-info">
              <h1>
                <a href="//jntkevote.netlify.app/">E-Voting Capstone Project</a>
              </h1>
              <h2>Voting With Blockchain</h2>
              <p>
                By using Blockchain technology on a public ledger, it allows
                votes to be more secured on a web platform. This application
                prohibits voter fraud, voter traceability all while allowing a
                fair and just democratic process void of interferences.
              </p>
            </div>
            <div className="project-img">
              <img src={evote} alt="img" />
            </div>
          </div>

          <div className="project-item">
            <div className="project-info">
              <h1>
                <a href="//andreasclassic.netlify.app/">
                  Andrea's Classic Catering
                </a>
              </h1>
              <h2>Local Catering Service</h2>
              <p>
                Being the lead web developer on this project, it was
                commissioned to create a page for a Western Catering Service.
                This project is currently continuous.
              </p>
            </div>
            <div className="project-img">
              <img src={catering} alt="img" />
            </div>
          </div>

          <div className="project-item">
            <div className="project-info">
              <h1>
                <a href="//nscam.netlify.app/">NSCAM Tours</a>
              </h1>
              <h2>Portland Touring Company</h2>
              <p>
                This project was curated to create a web application for clients
                to use for information regarding the Nature Scenic Cultural
                Adventurous Movement Tours company in Portland. This project is
                continuous.
              </p>
            </div>
            <div className="project-img">
              <img src={nscam} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default projects;
