import React from "react";
import image from "../../images/awards2.jpg";

function contact() {
  return (
    <section id="contact">
      <div className="contact main">
        <div>
          <h1 className="section-title">Contact Info</h1>
        </div>
        <div className="contact-items row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="contact-item">
              <div className="icon">
                <img src="https://img.icons8.com/clouds/100/undefined/apple-phone.png" />
              </div>
              <div className="contact-info">
                <h1>Phone</h1>
                <h2> 876-504-3325</h2>
                {/* <h2> 876-504-3325</h2> */}
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="contact-item">
              <div className="icon">
                <img src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/undefined/external-email-100-most-used-icons-flaticons-lineal-color-flat-icons.png" />
              </div>
              <div className="contact-info">
                <h1>Email</h1>
                <h2> javian99anderson@gmail.com</h2>
                {/* <h2> javian.christian@gmail.com</h2> */}
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="contact-item">
              <div className="icon">
                <img src="https://img.icons8.com/cute-clipart/64/undefined/linkedin.png" />
              </div>
              <div className="contact-info">
                <h1>LinkedIn</h1>
                <h2> jay andy balls</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default contact;
