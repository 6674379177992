import React from "react";
import { Footer2, Navbar2 } from "../../components";
import Slider from "../../components/Slider/Slider";
import studios from "../../images/studios-logo.png";
import tech from "../../images/tech-logo.png";
import entrep from "../../images/blogs/entrep.JPG";
import Helmet from "react-helmet";
// import { Link } from "react-router-dom";

function blog2() {
  const title = "My Entrepreneurial Journey | Javian Anderson";
  const url = "https://andersonja.tech/my-entrepreneurial-journey";
  const image = "https://i.ibb.co/LtzNfb2/entrep.png";
  const desc =
    "Having rediscovered the love I had being around computer and other devices, I had started couple business ventures in my university journey.";
  return (
    <div>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>{title}</title>
        <meta name="description" content={desc} data-react-helmet="true"></meta>

        {/* <!-- Open Graph / Facebook --> */}
        <meta
          property="og:type"
          content="website"
          data-react-helmet="true"
        ></meta>
        <meta property="og:url" content={url} data-react-helmet="true"></meta>
        <meta
          property="og:title"
          content={title}
          data-react-helmet="true"
        ></meta>
        <meta
          property="og:description"
          content={desc}
          data-react-helmet="true"
        ></meta>
        <meta
          property="og:image"
          content={image}
          data-react-helmet="true"
        ></meta>

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image"></meta>
        <meta
          property="twitter:url"
          content={url}
          data-react-helmet="true"
        ></meta>
        <meta
          property="twitter:title"
          content={title}
          data-react-helmet="true"
        ></meta>
        <meta
          property="twitter:description"
          content={desc}
          data-react-helmet="true"
        ></meta>
        <meta
          property="twitter:image"
          content={image}
          data-react-helmet="true"
        ></meta>
      </Helmet>
      <Navbar2 />
      <section id="blogs">
        <div className="blogs main">
          <div className="blogs-header pb-4">
            <h2 className="section-title">My Entrepreneurial Journey</h2>
            <h3>Timeline: September 2018-March 2019</h3>
            <h3 className="pt-2">Written: July 6,2022</h3>
          </div>

          <p className="m-5">
            I started The University of The West Indies(UWI) in 2018, where at
            the back of my mind was how I was delving into a completely new area
            of study that I had never considered before. From the talks with my
            quiz coach which lead me to realise that I had spent the majority of
            my handy life around computers and mobile devices, it dawned on me
            of a way to either continue developing those skills or find a way to
            use the talents I had to help others. Now, despite being on a
            scholarship offered to me through TVJ’s School’s Challenge Quiz
            competition, I was still not in the clear financially. So there the
            idea was! I should start a business! The rationale was that through
            this path, I could offer my services to others and give them a
            helping hand, while also gaining funds that would at least help with
            a few costs.
          </p>

          <p className="m-5">
            I had to tailor my business to University Students, as I knew that
            students like myself(especially those with no technical
            experience/knowledge) sometimes get caught in technical situations
            that require expertise outside of their natural domain and would
            require hefty fees to solve at times. So my first business plan was
            catered to Technical Solutions in the form of a business known as
            <span className="fs-3 fw-bolder fst-italic">“Zing Tech”</span>.
          </p>

          <div className="container text-center">
            <img src={tech} alt="Zing Tech Logo" className="img-fluid pot" />
            <h5>Zing Tech Logo</h5>
          </div>

          <div>
            <p className="m-5">
              Zing Tech was a business I built that catered to technical
              consultations, software solutions, and hardware solutions as well.
              It was a business model that I was proud of, as it felt like it
              fitted my natural state of helping others and doing something I
              loved. I must admit though, that at times it was hard balancing
              clients and school as a first-year university student, but I
              somehow managed, albeit barely.
            </p>
            <p className="m-5">
              In my first semester, I was inducted into Block E; The Manz of
              Mafia of Irvine Hall, and became their P.R.O(public relations
              officer). In this role, I was tasked with creating graphical
              content for the block, along with following up with events the
              block was participating in and sharing them across various media
              platforms. Shortly after being elected, I rediscovered what little
              talent I had for Photoshopping and Video Editing. This birthed
              another part of my business model which I dubbed{" "}
              <span className="fs-3 fw-bolder fst-italic">“Zing Studios”</span>.
              Zing Studios was created as a business that offered photography
              services, graphical content creation along with video editing and
              creation. It is important to note that I used my talents to create
              my business logos. I again found joy in this venture, especially
              the Video Editing part. I found my most exciting times to be when
              I was creating content for candidates in various elections across
              campus.
            </p>

            <div className="container text-center">
              <img
                src={studios}
                alt="Zing Studios Logo"
                className="img-fluid pot"
              />
              <h5>Zing Studios Logo</h5>
            </div>

            <p className="m-5">
              With these business ventures in full swing throughout my first
              year, I decided to enter an Entrepreneurship competition hosted by
              Irvine Hall. I was scared and anxious as I was one of two
              first-years in this competition, and I felt that my business plan
              wasn’t as good as the other participants. With this thought
              playing in the back of my mind, however, I dared myself to not
              become complacent and held my head down and put the work into my
              business proposal. Safe to say that method worked as I pushed hard
              and placed 3rd overall in the competition, a joyous feat for me of
              course.
            </p>

            <div className="container text-center">
              <img
                src={entrep}
                alt="Javian being presented with 3rd place rewards from Irvine Hall
                Entrepreneurship Competition"
                className="img-fluid"
              />
              <h5>
                Javian being presented with 3rd place rewards from Irvine Hall
                Entrepreneurship Competition
              </h5>
            </div>

            <p className="m-5">
              My second year was more of a struggle with having my business. I
              had taken on the role of Block Representative of Block E; The Manz
              of Mafia on Irvine Hall and the school work also ramped up. My
              businesses took a back burner, only being used for side quests and
              small jobs as I was no longer actively promoting it and looking
              for customers. I had a plan to resurrect it for my third year when
              I would seemingly be more relaxed in any leadership role, but, the
              Covid-19 pandemic hit and my businesses just continued being on
              the back burner.
            </p>

            <p className="m-5">
              I have since been doing projects under the name{" "}
              <span className="fs-3 fw-bolder fst-italic">
                “Zing Studio Productions”
              </span>{" "}
              which has now expanded to Web Development. Maybe one day I’ll
              resurrect my business ventures properly…Just maybe
            </p>
          </div>
        </div>
        <Footer2 />
      </section>
    </div>
  );
}

export default blog2;
